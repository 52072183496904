import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DocumentationComponent from "../components/documentation/documentation.component"

const Documentation = () => {
  return (
    <Layout>
      <DocumentationComponent />
    </Layout>
  )
}

export default Documentation

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
