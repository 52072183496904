import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"

import './documentation.component.less';

import IconPdf from "../../assets/img/icon/icon_pdf.svg"
import IconGit from "../../assets/img/icon/icon_git.svg"
import IconLink from "../../assets/img/icon/icon_link.svg"

import {Link} from "gatsby";
import SEO from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const DocumentationComponent = () => {
    const {i18n, t} = useTranslation()
    const {originalPath} = useI18next()

    return (
        <div>
            <SEO description={t("metaDescriptionDocumentation")}
                 lang={i18n.language} title={t("titleDocumentation")}
                 link={"https://cityair.ru/" + i18n.language + originalPath}/>
            <div
                className="wrapper_container_main align_center_ipad wrapper_container_main-flex">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                            <div
                                className="color_purple documentation_main_name">{t("documentation")}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-5">
                            <div
                                className="documentation_main_title">{t("projectDocumentationAnd")}</div>
                        </div>
                        <div
                            className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xga-1 col-fhd-1 col-rt-1"/>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-6 col-xga-6 col-fhd-6 col-rt-6">
                            <div className="documentation_main_description_1">
                                {t("onThisPageYou")}
                            </div>
                            <div
                                className="color_gray documentation_main_description_2">
                                {t("weConstantlyImprove")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrapper_container wrapper_container-gray">
                <div className="container documentation_block_6__container">
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__title">
                            {t("projectDocumentation")}
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("documentationForSpecialists")}
                        </div>
                    </div>
                    
                    <div className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a href="https://developers.cityair.ru/" target="_blank">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a className="color_purple" target="_blank" href="https://developers.cityair.ru/">developers.cityair.ru</a>&nbsp;&mdash; {t("developersAPI")}
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("instructions")}
                        </div>
                    </div>
                    <div className="documentation-block">
                        <div className="row color_black_light documentation_block_6__row">
                            
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                                <div className="documentation_block_6__icon">
                                    <a download
                                    href="/docs/Инструкция_по_монтажу.pdf">
                                        <img alt="" src={IconPdf}/>
                                    </a>
                                </div>
                                <div className="documentation_block_6__item-title">
                                    <a target="_blank" className="color_purple"
                                    download
                                    href="/docs/Инструкция_по_монтажу.pdf">{t("APIForAccessMonitoring")}</a>
                                </div>
                            </div>

                            <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                                <div className="documentation_block_6__icon">
                                    <a label="Руководство пользователя CityAir Forecast"
                                    download
                                    href="/docs/forecast/cityair-forecast.pdf">
                                        <img alt="" src={IconPdf}/>
                                    </a>
                                </div>
                                <div className="documentation_block_6__item-title">
                                    <a className="color_purple" download
                                    href="/docs/forecast/cityair-forecast.pdf">
                                        {t("userManualPlums")}</a>&nbsp;&mdash; CityAir.Forecast <br />Система расчета переноса химически активных примесей в атмосферном воздухе
                                </div>
                            </div>

                        </div>
                        <div className="row color_black_light documentation_block_6__row">
                            <div
                                className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                                <div className="documentation_block_6__icon">
                                    <a label="Руководство пользователя CityAir Plume"
                                    download
                                    href="/docs/plume/CityAir.Plume.pdf">
                                        <img alt="" src={IconPdf}/>
                                    </a>
                                </div>
                                <div className="documentation_block_6__item-title">
                                    <a className="color_purple" download
                                    href="/docs/plume/CityAir.Plume.pdf">
                                        {t("userManualPlums")}</a>&nbsp;&mdash; CityAir.Plume <br />Система визуализации рассеивания выбросов от источников загрязнения воздуха
                                </div>
                            </div>
                            <div
                                className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600"/>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                                <div className="documentation_block_6__icon">
                                    <a label="Руководство по эксплуатации CityAir 1.1 rus"
                                    download
                                    href="/docs/basic/CityAir.Basic-user-manual.pdf">
                                        <img alt="" src={IconPdf}/>
                                    </a>
                                </div>
                                <div className="documentation_block_6__item-title">
                                    <a className="color_purple" download
                                    href="/docs/basic/CityAir.Basic-user-manual.pdf">
                                        {t("userManualPlums")}</a>&nbsp;&mdash; CityAir.Basic <br />Система сбора, хранения, обработки и визуализации данных о качестве атмосферного воздуха
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("licensesAndCertificates")}
                        </div>
                    </div>
                    <div
                        className="row color_black_light documentation_block_6__row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Basic.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Basic.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Basic")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>


                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Forecast.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Forecast.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Forecast")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/SW_FIPS_Registration_CityAir_Plume.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/SW_FIPS_Registration_CityAir_Plume.pdf">
                                    {t("SW_FIPS_Registration_CityAir_Plume")}
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/918428/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/918428/">
                                    {t("registrySoftwareBasic")}
                                </a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/309010/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/309010/">
                                    {t("registrySoftwarePlume")}
                                </a>
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr-pak/1807229/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr-pak/1807229/">Реестр программного обеспечения. Программно-аппаратный комплекс «Системы мониторинга качества воздуха CityAir»</a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/1483934/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/1483934/">Реестр программного обеспечения. CityAir Core Firmware</a>
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/1460077/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/1460077/">Реестр программного обеспечения. CityAir Dust</a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/1483932/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/1483932/">Реестр программного обеспечения. CityAir G1/G2&nbsp;&mdash; ExtensionModule</a>
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/1483930/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/1483930/">Реестр программного обеспечения. CityAir G3A&nbsp;&mdash; G3HUB</a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://reestr.digital.gov.ru/reestr/1483950/">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://reestr.digital.gov.ru/reestr/1483950/">Реестр программного обеспечения. CityAir.Gateway</a>
                            </div>
                        </div>

                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/324/2022683324-00001/DOCUMENT.PDF">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/324/2022683324-00001/DOCUMENT.PDF">Свидетельство о государственной регистрации программы для ЭВМ. CityAir Core Firmware</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/684/408/2022684408-00001/DOCUMENT.PDF">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/684/408/2022684408-00001/DOCUMENT.PDF">Свидетельство о государственной регистрации программы для ЭВМ. CityAir Dust</a>
                            </div>
                        </div>

                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/849/2022683849-00001/DOCUMENT.PDF">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/849/2022683849-00001/DOCUMENT.PDF">Свидетельство о государственной регистрации программы для ЭВМ. CityAir G1/G2&nbsp;&mdash; ExtensionModule </a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/325/2022683325-00001/DOCUMENT.PDF">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/683/325/2022683325-00001/DOCUMENT.PDF">Свидетельство о государственной регистрации программы для ЭВМ. CityAir G3A&nbsp;&mdash; G3HUB</a>
                            </div>
                        </div>

                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/684/194/2022684194-00001/DOCUMENT.PDF">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://www1.fips.ru/ofpstorage/Doc/PrEVM/RUNWPR/000/002/022/684/194/2022684194-00001/DOCUMENT.PDF">Свидетельство о государственной регистрации программы для ЭВМ. CityAir.Gateway</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end_1 documentation_block_6__string-end">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/patent-for-invention-2796370.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/patent-for-invention-2796370.pdf">Патент на изобретение №2796370. Система и способ динамической визуализации загрязнения окружающей среды</a>
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            {t("equipment")}
                        </div>
                    </div>
                    <div
                        className="row color_black_light documentation_block_6__row documentation_block_6__row-end">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/789707">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/789707">Пылемеры CityAir Dust. Регистрационный №75984-19</a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/1228914">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/1228914">Модули расширения G. Регистрационный №78092-20</a>
                            </div>
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/1407225">
                                    <img alt="" src={IconLink}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="https://fgis.gost.ru/fundmetrology/registry/4/items/1407225">Многоканальный газоанализатор G3.A Регистрационный №88474-23</a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/patent-for-invention-2785169.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/patent-for-invention-2785169.pdf">Патент на изобретение №2785169. Многоканальный газоанализатор и способ газового анализа</a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/patent-for-invention-2771786.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/patent-for-invention-2771786.pdf">Патент на изобретение №2771786. Способ и&nbsp;устройство контроля концентрации газов в воздухе</a>
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/patent-for-invention-202130.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/patent-for-invention-202130.pdf">Патент на полезную модель №202130. Устройство контроля концентрации газов в воздухе</a>
                            </div>
                        </div>

                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end documentation_block_6__string-end_1">
                            <div className="documentation_block_6__icon">
                                <a target="_blank"
                                   href="/docs/patent-for-invention-2709410.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   href="/docs/patent-for-invention-2709410.pdf">Патент на изобретение №2709410. Измеритель, система и способ измерения массовой концентрации пылевых частиц</a>
                            </div>
                        </div>
                        <div className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xga-5 col-fhd-5 col-rt-5 documentation_block_6__string documentation_block_6__string-end">
                            <div className="documentation_block_6__icon">
                                <a download href="/docs/Datasheet_CityAir.pdf">
                                    <img alt="" src={IconPdf}/>
                                </a>
                            </div>
                            <div className="documentation_block_6__item-title">
                                <a target="_blank" className="color_purple"
                                   download href="/docs/Datasheet_CityAir.pdf">
                                    Datasheet</a>&nbsp;&mdash; технические характеристики оборудования CityAir
                            </div>
                        </div>
                        <div
                            className="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-0 col-xga-1 col-fhd-1 col-rt-1 display_none_1600">
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__sub_title">
                            Отчеты о проведении специальной оценки условий труда компании:
                        </div>
                    </div>

                    <div className="row color_black_light">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string_full">
                            <div>
                                <div className="documentation_block_6__link"><a target="_blank" className="color_purple" href="/docs/Спецоценка-05.2019.pdf" download>Спецоценка 05.2019</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string_full">
                            <div>
                                <div className="documentation_block_6__link"><a target="_blank" className="color_purple" href="/docs/Спецоценка-01.2022.pdf" download>Спецоценка 01.2022</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string_full">
                            <div>
                                <div className="documentation_block_6__link"><a target="_blank" className="color_purple" href="/docs/Спецоценка-04.2022.pdf" download>Спецоценка 04.2022</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string_full">
                            <div>
                                <div className="documentation_block_6__link"><a target="_blank" className="color_purple" href="/docs/Спецоценка-12.2022.pdf" download>Спецоценка 12.2022</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12 documentation_block_6__string documentation_block_6__string_full">
                            <div>
                                <div className="documentation_block_6__link"><a target="_blank" className="color_purple" href="/docs/Спецоценка-03.2024.pdf" download>Спецоценка 03.2024</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

DocumentationComponent.propTypes = {}

DocumentationComponent.defaultProps = {}

export default DocumentationComponent
